import {Container, Backdrop, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {OneHitTopThreeCard} from "../../Components/OneHitTopThree";
import {OneHitListItem} from "../../Interfaces/Api";
import useRunOnce from "../../Tools/RunOnce";

export default function OneHit(): JSX.Element {
	const [listItems, setListItems] = useState<OneHitListItem[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [topThree, setTopThree] = useState<OneHitListItem[]>([]);

	useRunOnce({
		fn: () => {
			//#region LOAD SHOW
			setIsLoading(true);

			fetch("/api/lst_onehit.php", {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: null,
			})
				.then((response) => response.json())
				.then((data: OneHitListItem[]) => {
					setListItems(data);
				})
				.finally(() => {
					setIsLoading(false);
				});
			//#endregion
		},
	});

	useEffect(() => {
		if (listItems) {
			setTopThree(listItems.slice(0, 3));
		}
	}, [listItems]);

	return (
		<Container maxWidth='lg'>
			<Helmet>
				<title>One Hit Wonders</title>
				<meta name='description' content='Shows with a single season rated above 8.5.'></meta>
			</Helmet>
			{isLoading ? (
				<Backdrop open={true} sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}>
					<CircularProgress color='secondary' />
				</Backdrop>
			) : (
				listItems &&
				listItems.length > 0 && (
					<Grid2 container spacing={2}>
						<Grid2 xs={12}>
							<Typography variant='h1' sx={{my: 2, textAlign: "center"}}>
								One Hit Wonders
							</Typography>
							<Typography variant='h2' sx={{textAlign: "center"}}>
								Shows with a single season rated above 8.5.
							</Typography>
						</Grid2>
						<Grid2 xs={12}>
							<Grid2 container spacing={3}>
								{topThree &&
									topThree.length > 0 &&
									topThree.map((gi: OneHitListItem, i: number) => {
										return (
											<Grid2 xs={12} md={4} key={`topThree${i}`}>
												<OneHitTopThreeCard item={gi} index={i}></OneHitTopThreeCard>
											</Grid2>
										);
									})}
							</Grid2>
						</Grid2>
						<Grid2 xs={12}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Rank</TableCell>
											<TableCell>Title</TableCell>
											<TableCell>Average Rating</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{listItems.map((gi: OneHitListItem, index: number) => {
											return (
												<TableRow key={`genreRow${index}`}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<Link href={`/${gi.cleantitle}`} underline='hover' target='_self' color='inherit'>
															{gi.title}
														</Link>
													</TableCell>
													<TableCell>{gi.rating.toFixed(1)}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid2>
					</Grid2>
				)
			)}
		</Container>
	);
}
