import {Box, Grid} from "@mui/material";
import {Container} from "@mui/system";
import Link from "@mui/material/Link";
import {Helmet} from "react-helmet-async";

export default function About(): JSX.Element {
	return (
		<Container maxWidth='sm'>
			<Helmet>
				<title>{`About`}</title>
				<meta name='description' content='Information about the creator, purpose of the site, and how to donate.' />
			</Helmet>
			<Grid
				container
				spacing={3}
				sx={{
					my: 3,
					display: "flex",
					flexGrow: 1,
					alignGrids: "center",
					justifyContent: "center",
				}}
			>
				<Grid item xs={12}>
					<h2>About w2wTV</h2>
					<p>
						What to Watch on TV was created as a way for me to play around with different programming languages. It uses IMDb data to generate
						graphs and tables of data about television shows and episodes.
					</p>
					<p>
						If you found this site useful and want to show your appreciation, you can{" "}
						<Link href='https://ko-fi.com/V7V31LPMW' underline='hover' target='_blank' rel='noreferrer' color='inherit'>
							Buy Me a Coffee at ko-fi.com
						</Link>{" "}
						or{" "}
						<Link href='https://www.extra-life.org/participant/randyslavey' underline='hover' target='_blank' rel='noreferrer' color='inherit'>
							Donate to Children"s Hospital Colorado
						</Link>
						. Cheers!
					</p>
				</Grid>
				<Grid item xs={12} sm={6} sx={{display: "flex", justifyContent: "center", alignContent: "center"}}>
					<Link href='https://ko-fi.com/V7V31LPMW' target='_blank' rel='noreferrer'>
						<Box
							component='img'
							sx={{
								width: "100%",
							}}
							alt='kofi donate button'
							src='/images/kofi_bg_tag_dark.png'
						/>
					</Link>
				</Grid>
				<Grid item xs={12} sm={6} sx={{display: "flex", justifyContent: "center", alignContent: "center"}}>
					<Link href='https://www.extra-life.org/participant/randyslavey' target='_blank' rel='noreferrer'>
						<Box
							component='img'
							sx={{
								width: "100%",
							}}
							alt='extra life donate button'
							src='/images/extra-life-support.png'
						/>
					</Link>
				</Grid>
			</Grid>
		</Container>
	);
}
