import {Backdrop, CircularProgress, Container} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {HeatMap} from "../Components/HeatMap";
import {OmdbShow, RandomShowObject, ShowObject} from "../Interfaces/Api";
import useRunOnce from "../Tools/RunOnce";
import {RadarGraph} from "../Components/RadarGraph";
import Grid2 from "@mui/material/Unstable_Grid2";
import {ShowSummary} from "../Components/ShowSummary";
import {SeasonLine} from "../Components/SeasonLIne";
import useMeasure from "react-use-measure";
import {GetTitleFromShowTitle} from "../Tools/Tools";
import {Helmet} from "react-helmet-async";
import ColorKey from "../Components/ColorKey";

export default function Show(): JSX.Element {
	const [lineChartContainer, {width}] = useMeasure();
	const [isLoadingShow, setIsLoadingShow] = useState<boolean>();
	const [show, setShow] = useState<ShowObject>();
	const [omdbShow, setOmdbShow] = useState<OmdbShow>();
	const [pageDescription, setPageDescription] = useState<string>("Visualize televsion show ratings, compare shows, browse curated lists, see genres.");
	let {showId} = useParams();

	const queryParams = new URLSearchParams(window.location.search);
	const isPrint: boolean = queryParams.get("isPrint") === "1";

	useRunOnce({
		fn: () => {
			//#region LOAD SHOW
			setIsLoadingShow(true);

			fetch("/api/gettitle.php", {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({show: showId}),
			})
				.then((response) => response.json())
				.then((data: RandomShowObject) => {
					fetch("/api/gettitle.php", {
						method: "POST",
						headers: {"Content-Type": "application/json"},
						body: JSON.stringify({show: data.CleanTitle}),
					})
						.then((response) => response.json())
						.then((showData: ShowObject) => {
							setShow(showData);
							fetch(`https://www.omdbapi.com/?i=${showData.Id}&apikey=da6a49dd`, {method: "GET"})
								.then((response) => response.json())
								.then((data: OmdbShow) => {
									setOmdbShow(data);
								});
						});
				})
				.finally(() => {
					setIsLoadingShow(false);
				});
			//#endregion
		},
	});

	return (
		<Container maxWidth={isPrint ? "xl" : false}>
			{show && (
				<Helmet>
					<title>{`${GetTitleFromShowTitle(show.ShowTitle)}`}</title>
					<meta property='og:title' content={`${GetTitleFromShowTitle(show.ShowTitle)}`} />
					<meta property='og:type' content='website' />
					<meta property='og:url' content={window.location.href} />
					<meta name='description' content={pageDescription} />
					<meta property='og:image' content={`/screenshots/${show.CleanTitle}.jpg`} />
				</Helmet>
			)}
			{isLoadingShow ? (
				<Backdrop open={true} sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}>
					<CircularProgress color='secondary' />
				</Backdrop>
			) : (
				<Grid2
					container
					spacing={2}
					sx={{
						mx: {xs: 2},
						my: {xs: 2, lg: "auto"},
						display: "flex",
						flexGrow: 1,
						justifyContent: "space-between",
					}}
				>
					{isPrint && <Grid2 xs={12}>{show && <ShowSummary omdbObject={omdbShow} showObject={show} isPrint={isPrint}></ShowSummary>}</Grid2>}
					<Grid2 xs={12} md={isPrint ? 12 : 9}>
						<Grid2 container spacing={4}>
							{isPrint && <Grid2 xs={12}>{show && <ColorKey></ColorKey>}</Grid2>}
							<Grid2 xs={12} md={6}>
								{show && <HeatMap isPrint={isPrint} showObject={show}></HeatMap>}
							</Grid2>
							<Grid2 xs={12} md={6}>
								{show && <RadarGraph showSeasonLabels={true} showObject={show}></RadarGraph>}
							</Grid2>
							<Grid2 xs={12} ref={lineChartContainer}>
								{show && <SeasonLine show={show} width={width} />}
							</Grid2>
						</Grid2>
					</Grid2>
					{!isPrint && (
						<Grid2 xs={12} md={3}>
							{show && <ShowSummary omdbObject={omdbShow} showObject={show} isPrint={isPrint}></ShowSummary>}
						</Grid2>
					)}
				</Grid2>
			)}
		</Container>
	);
}
