import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {NavMenuItem, SearchResult, ShowResult} from "../Interfaces/Api";
import {Autocomplete, TextField} from "@mui/material";
import {useEffect} from "react";

const pages: NavMenuItem[] = [
	{Display: "Genres", Url: "/genrelist"},
	{Display: "Compare", Url: "/compare"},
	{Display: "Lists", Url: "/lists"},
	{Display: "About", Url: "/about"},
];

export default function ResponsiveAppBar() {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [inputValue, setInputValue] = React.useState<string>("");
	const [options, setOptions] = React.useState<readonly ShowResult[]>([]);

	const queryParams = new URLSearchParams(window.location.search);

	const searchShows = (query: string): void => {
		fetch("/api/titlesearch.php", {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({
				curshow: "",
				query: query,
				sortorder: "rating",
				minrating: 6,
				pageno: 1,
				pagesize: 20,
				stillrunning: false,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data: SearchResult) => {
				setOptions(data.ShowList.filter((show) => !options.includes(show)));
			});
	};
	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const openNavItem = (link: string) => {
		handleCloseNavMenu();
		window.open(link, "_self");
	};

	useEffect(() => {
		if (inputValue && inputValue.length > 2) {
			searchShows(inputValue);
		} else {
			setOptions([]);
		}
	}, [inputValue]);

	return (
		<AppBar position='static'>
			<Container maxWidth={false}>
				<Toolbar
					disableGutters
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Typography
						variant='h6'
						noWrap
						component='a'
						href='/'
						sx={{
							mr: 2,
							display: {xs: "none", md: "flex"},
							fontFamily: "monospace",
							fontWeight: 700,
							letterSpacing: ".3rem",
							color: "inherit",
							textDecoration: "none",
						}}
					>
						w2wTV
					</Typography>

					<Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
						<IconButton size='large' aria-label='menu' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleOpenNavMenu} color='inherit'>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: {xs: "block", md: "none"},
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page.Display} onClick={() => openNavItem(page.Url)}>
									<Typography textAlign='center'>{page.Display}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Typography
						variant='h5'
						noWrap
						component='a'
						href=''
						sx={{
							mr: 2,
							display: {xs: "flex", md: "none"},
							flexGrow: 1,
							fontFamily: "monospace",
							fontWeight: 700,
							letterSpacing: ".3rem",
							color: "inherit",
							textDecoration: "none",
						}}
					>
						w2wTV
					</Typography>

					<Box sx={{flexGrow: 2, justifyContent: "center", flexDirection: "row", maxWidth: "50vw"}}>
						<Autocomplete
							id='show-search'
							getOptionLabel={(option) => (typeof option === "string" ? option : option.ShowTitle)}
							filterOptions={(x) => x}
							options={options}
							autoComplete
							filterSelectedOptions
							includeInputInList
							value={undefined}
							noOptionsText='No results'
							onChange={(event: any, newValue: ShowResult | null) => {
								if (newValue) {
									window.open(`/${newValue.CleanTitle}`, "_self");
								}
							}}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							renderInput={(params) => {
								return <TextField {...params} label='Search for a show' fullWidth />;
							}}
						/>
					</Box>

					<Box sx={{justifyContent: "end", display: {xs: "none", md: "flex"}}}>
						{pages.map((page) => (
							<Button key={page.Display} component='a' href={page.Url} sx={{mx: 2, my: 2, color: "white", display: "block"}}>
								{page.Display}
							</Button>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
